<template>
  <v-container>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="primaryInfoStepCompleted" step="1" editable edit-icon="mdi-check">
        Enter your project name
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-text-field
          :label="$t('form.create_project.humman_name.label')"
          v-model="hummanName"
          :rules="[validProjectName]"
          autofocus
          :disabled="waitingRequestToCreateProject"
        />
        <v-text-field
          :label="$t('form.create_project.project_id.label')"
          v-model="projectId"
          :rules="[validProjectId, availableProjectId]"
          :loading="projectIdAvailableWaiting"
          :success="projectIdAvailable"
          persistent-hint
          hint="Your project's globally unique identifier. You cannot change your project ID after project creation."
          :disabled="waitingRequestToCreateProject"
        />
        <v-card-actions class="px-0">
          <v-btn color="primary" :disabled="!primaryInfoStepCompleted || waitingRequestToCreateProject" @click="e6 = 2">Next</v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step :complete="repoStepCompleted" step="2" editable>
        Connect your project to git repository
        <small>Your project is in your repository. We need read-only access for deploying.</small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-text-field
          :label="$t('form.create_project.repo_url.label')"
          v-model="repoURL"
          :rules="[validRepoURL]"
          :disabled="waitingRequestToCreateProject"
        />
        <v-text-field
          :label="$t('form.create_project.repo_username.label')"
          v-model="repoURLUsername"
          :disabled="waitingRequestToCreateProject"
        />
        <v-text-field
          :label="$t('form.create_project.repo_password.label')"
          type="password"
          v-model="repoURLPassword"
          :disabled="waitingRequestToCreateProject"
        />
        <v-card-actions class="px-0">
          <v-btn color="primary mt-2" :disabled="!(primaryInfoStepCompleted && repoStepCompleted) || waitingRequestToCreateProject" @click="e6 = 3">Next</v-btn>
        </v-card-actions>
      </v-stepper-content>

      <v-stepper-step :complete="primaryInfoStepCompleted && repoStepCompleted" step="3">
        Summary info
      </v-stepper-step>

      <v-stepper-content step="3">
        <p><strong>{{ hummanName }}</strong> (unique identifier <v-chip x-small>{{ projectId }}</v-chip>).</p>
        <p>Git repo: {{ repoURL?repoURL: 'no' }}.</p>
        <v-btn color="primary" @click="doCreateProject" :loading="waitingRequestToCreateProject"> Create Project </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import api from "../api";
export default {
  name: "CreateProject",
  components: {},
  methods: {
    doCreateProject() {
      if (!this.availableCreateProject) {
        this.$notifWarn('The project data is not valid.')
        return
      }
      this.waitingRequestToCreateProject = true
      const newProjectData = {
        projectId: this.projectId,
        hummanName: this.hummanName,
        repo: {
          url: this.repoURL,
          username: this.repoURLUsername,
          password: this.repoURLPassword,
        }
      }
      api.project.create(newProjectData).then((res) => {
        this.clearForm()
        this.$notifSuccess(this.$t('notif.success_create_project',[newProjectData.hummanName, newProjectData.projectId]), 5000)
        this.$router.push({name: "ProjectOverview", params: {projectId: this.projectId}})
      }).finally(() => {
        this.waitingRequestToCreateProject = false
      })
    },
    clearForm() {
      this.projectId = ""
      this.hummanName = ""
      this.repoURL = ""
      this.repoURLUsername = ""
      this.repoURLPassword = ""
    }
  },
  computed: {
    availableCreateProject() {
      return this.primaryInfoStepCompleted == true && this.repoStepCompleted == true
    },
    primaryInfoStepCompleted() {
      return this.validProjectName == true && this.validProjectId == true && this.availableProjectId == true && !this.projectIdAvailableWaiting
    },
    repoStepCompleted() {
      return this.validRepoURL == true
    },
    validProjectName() {
      if (this.hummanName.length >= 2) {
        return true
      }
      return 'The name is required (min 2 letters).'
    },
    validProjectId() {
      if (this.projectId.length < 6) {
        return 'The project ID is required (min 6 letters, max 30 letters).'
      }
      if (this.projectId.length > 30) {
        return 'The project ID is required (min 6 letters, max 30 letters).'
      }
      if (/^[a-z]{2,}[a-z0-9-]*[a-z0-9]+$/.test(this.projectId)) {
        this.projectIdAvailableWaiting = true
        api.project.checkAvailableProjectID({
          projectId: this.projectId,
        }).then((res) => {
          console.log('success', res)
          this.projectIdAvailable = true
        }).catch(err => {
          console.log('fails', err)
          this.projectIdAvailable = false
        }).finally(() => {
          this.projectIdAvailableWaiting = false
        })
        return true
      }
      return 'Project ID can have lowercase letters, digits, or hyphens. It must start with a lowercase letter and end with a letter or number.'
    },
    availableProjectId() {
      if (this.projectIdAvailable) {
        return true
      }
      return 'Project ID is not available.'
    },
    validRepoURL() {
      if (/^http(s)?:\/\/.*$/.test(this.repoURL)) {
        return true
      }
      return 'Must be a valid http URL. For example https://github.com/octocat/Hello-World.git.'
    }
  },
  watch: {
    // projectId(inputProjectId) {
    // },
  },
  data: () => ({
    e6: 1,
    projectId: "",
    projectIdAvailable: false,
    projectIdAvailableWaiting: false,
    hummanName: "",
    repoURL: "",
    repoURLUsername: "",
    repoURLPassword: "",

    waitingRequestToCreateProject: false,
  }),
};
</script>
